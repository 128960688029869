<template>
  <aside>
    <header>
      <h2>{{modal_title}}</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main>
      <virtual-list class="virtual-list" :data-key="'id'" :data-component="rowComponent" :data-sources="data.list"></virtual-list>
    </main>
  </aside>
</template>

<script>
import VirtualList from 'vue-virtual-scroll-list';
import UserListRow from './UserListRow'

export default {
  components: {
    VirtualList,
    UserListRow
  },
  props: ['data'],
  computed: {
    modal_title() {
      return this.data.modal_title;
    }
  },
  data() {
    return {
      rowComponent: UserListRow
    }
  },
  created() {

  },
  methods: {
    close() {
      this.$emit('done');
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  height: calc(100% - 70px);
}
.virtual-list {
  height: 100%;

  ::v-deep .user-list-row {
    border-bottom: 1px solid $light;
    padding: 10px 0;
  }
}
</style>